import { memo } from 'react';
import Head from 'next/head';

const Meta = memo(
    ({ title, description, ogImage, twitterImage, noIndex = false }) => {
        if (typeof ogImage === 'undefined') {
            var ogImage = 'https://www.crisp.co.jp/image/ogp/ogp.jpg';
        }
        if (typeof twitterImage === 'undefined') {
            var twitterImage = 'https://www.crisp.co.jp/image/ogp/ogp.jpg';
        }
        return (
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content="keywords" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/image/favicon/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/image/favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/image/favicon/favicon-16x16.png"
                />
                <link rel="manifest" href="/image/favicon/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/image/favicon/safari-pinned-tab.svg"
                    color="#0c3b2c"
                />
                <meta name="msapplication-TileColor" content="#0c3b2c" />
                <meta name="theme-color" content="#0c3b2c" />

                <meta property="og:type" content="website" />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content="" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@crispsaladworks" />
                <meta name="twitter:image" content={twitterImage} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
                />
                {/* <meta
                httpEquiv="Content-Security-Policy"
                content="script-src 'none'"
            ></meta> */}
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap"
                    rel="stylesheet"
                />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&amp;display=swap"
                    as="style"
                    rel="stylesheet preload"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&amp;display=swap"
                    as="style"
                    rel="stylesheet preload"
                />
                {noIndex && <meta name="robots" content="noindex" />}
            </Head>
        );
    }
);

Meta.displayName = 'Meta';

export default Meta;
