import { memo, useEffect } from 'react';
import useUserAgent from '../../hooks/useUserAgent';
import Header from './Header';
import Footer from './Footer';

const Layout = memo((props) => {
    const breakPoint = 768;
    useEffect(() => {
        const isMobileLayout = breakPoint > window.innerWidth;
        window.addEventListener('resize', () => {
            if (isMobileLayout) {
                if (window.innerWidth >= breakPoint) {
                    location.reload();
                }
            } else {
                if (window.innerWidth < breakPoint) {
                    location.reload();
                }
            }
        });
    }, []);

    const { device } = useUserAgent();

    return (
        <div className={`${device}`}>
            <Header />
            <main>{props.children}</main>
            <Footer />
        </div>
    );
});

Layout.displayName = 'Layout';

export default Layout;
