let storiesCategoryItems = [
    {
        key: 1,
        href: '/stories?category=すべての記事',
        text: 'すべての記事',
    },
    {
        key: 2,
        href: '/stories?category=お店のひみつ',
        text: 'お店のひみつ',
    },
    {
        key: 3,
        href: '/stories?category=サラダのひみつ',
        text: 'サラダのひみつ',
    },
    {
        key: 4,
        href: '/stories?category=お知らせ',
        text: 'お知らせ',
    },
    {
        key: 5,
        href: '/stories?category=重要なお知らせ',
        text: '重要なお知らせ',
    },
];

export default storiesCategoryItems;
