import React, { useEffect, useRef, useState } from 'react';

const useSpAccordion = () => {
    const [active, setActive] = useState(false);
    const [elementHeight, setElementHeight] = useState(0);
    const contentRef = useRef(null);
    const breakPoint = 768;

    useEffect(() => {
        if (window.innerWidth < breakPoint) {
            setElementHeight(contentRef.current.scrollHeight);
            if (!active) {
                contentRef.current.style.maxHeight = `0px`;
            }
            window.addEventListener('resize', () => {
                setElementHeight(contentRef.current.scrollHeight);
                contentRef.current.style.maxHeight = active
                    ? `${contentRef.current.scrollHeight}px`
                    : '0px';
            });
        }
    }, [contentRef, active]);

    const toggleActive = () => {
        setActive(!active);
        setElementHeight(0);
        contentRef.current.style.maxHeight = active
            ? '0'
            : `${elementHeight}px`;
    };

    return { contentRef, active, toggleActive };
};

export default useSpAccordion;
