import React, { useEffect, useState } from 'react';

const useUserAgent = () => {
    const [device, setDevice] = useState(null);
    const [mobileType, setMobileType] = useState(null);
    useEffect(() => {
        const ua = window.navigator.userAgent.toLowerCase();
        if (
            ua.indexOf('iphone') > 0 ||
            ua.indexOf('ipod') > 0 ||
            (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
        ) {
            setDevice('deviceMobile');
            if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0) {
                setMobileType('mobileApple');
            } else if (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
                setMobileType('mobileAndroid');
            } else {
                setMobileType('mobileOther');
            }
        } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            // iOS12 まで
            setDevice('deviceTab');
        } else if (
            ua.indexOf('ipad') > -1 ||
            (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
        ) {
            // iOS13 以降
            setDevice('deviceTab');
        } else {
            setDevice('deviceOther');
        }
    }, [device, mobileType]);

    return { device, mobileType };
};

export default useUserAgent;
