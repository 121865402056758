import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';

const ActiveLink = memo(({ href, text, type }) => {
    const router = useRouter();

    return (
        <Link href={href}>
            <a
                className={`${type} 
                       headerNavLink js-hover js-hover__color__point
                       ${router.pathname.startsWith(href) ? 'is-active' : ''}`}
                data-hover-in-time="900"
                data-hover-out-time="0"
            >
                {text}
            </a>
        </Link>
    );
});

ActiveLink.displayName = 'ActiveLink';

export default ActiveLink;
