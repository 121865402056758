import { memo } from 'react';
import styles from '../../../styles/components/section/common/Img.module.scss';

const Img = memo(
    ({ hover, src, spSrc, webpSrc, webpSpSrc, alt, loading = 'lazy' }) => {
        return (
            <picture>
                {webpSpSrc && (
                    <source
                        media="(max-width:768px)"
                        type="image/webp"
                        srcSet={webpSpSrc}
                    />
                )}
                {spSrc && <source media="(max-width:768px)" srcSet={spSrc} />}
                {webpSrc && <source srcSet={webpSrc} type="image/webp" />}
                <img
                    className={`${styles.main} ${hover ? 'js-hover__img' : ''}`}
                    src={src}
                    alt={alt}
                    loading={loading}
                />
            </picture>
        );
    }
);

Img.displayName = 'Img';

export default Img;
