import React, { useEffect, useState } from 'react';

const useWindowWidth = () => {
    const breakPoint = 768;
    const [windowWidth, setWindowWidth] = useState(null);
    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, []);
    return { breakPoint, windowWidth };
};

export default useWindowWidth;
