import { memo } from 'react';
import styles from '../../styles/components/icon/ArrowX.module.scss';

const ArrowX = memo(({ color = '#0c3b2c' }) => {
    return (
        <span className={`${styles.container} js-hover__arrow__x`}>
            <svg
                className={`${styles.arrow} js-hover__arrow__x-svg`}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 6L11.0018 6"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.00085 11L11.0009 6.00082L6.00085 1"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <svg
                className={`${styles.arrow} js-hover__arrow__x-svg`}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 6L11.0018 6"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.00085 11L11.0009 6.00082L6.00085 1"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </span>
    );
});

ArrowX.displayName = 'ArrowX';

export default ArrowX;
