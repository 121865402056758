export let navSubHelpItems = [
    {
        key: 1,
        href: 'https://help.crispsaladworks.com/hc/ja',
        text: 'HELP',
        main: 'main',
        arrow: true,
    },
    {
        key: 2,
        href: 'https://help.crispsaladworks.com/hc/ja/sections/900000780266-%E6%96%B0%E5%9E%8B%E3%82%B3%E3%83%AD%E3%83%8A%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%81%B8%E3%81%AE%E5%AF%BE%E5%BF%9C',
        text: '新型コロナウイルスへの対応',
        main: '',
        arrow: true,
    },
    {
        key: 3,
        href: 'https://help.crispsaladworks.com/hc/ja/sections/900000784086-%E3%82%B5%E3%83%A9%E3%83%80%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
        text: 'サラダについて',
        main: '',
        arrow: true,
    },
    // {
    //     key: 4,
    //     href: 'https://help.crispsaladworks.com/hc/ja/sections/900001785103-%E3%82%B5%E3%83%A9%E3%83%80%E3%82%92%E6%B3%A8%E6%96%87%E3%81%99%E3%82%8B',
    //     text: 'サラダを注文する',
    //     main: '',
    //     arrow: true,
    // },
];

//
//
//

export let navSubCareersItems = [
    {
        key: 1,
        href: 'https://corp.crisp.co.jp/careers/',
        text: 'CAREERS',
        main: 'main',
        arrow: true,
    },
    {
        key: 2,
        href: 'https://corp.crisp.co.jp/careers/farm/',
        text: 'THE FARM',
        main: '',
        arrow: true,
    },
    {
        key: 3,
        href: 'https://corp.crisp.co.jp/careers/restaurant/',
        text: 'RESTAURANT',
        main: '',
        arrow: true,
    },
];

//
//
//

export let navSubCorporateItems = [
    {
        key: 1,
        href: 'https://corp.crisp.co.jp/',
        text: 'CORPORATE',
        main: 'main',
        arrow: true,
    },
    {
        key: 2,
        href: 'https://corp.crisp.co.jp/vision/',
        text: 'OUR VISION',
        main: '',
        arrow: true,
    },
    {
        key: 3,
        href: 'https://corp.crisp.co.jp/service/',
        text: 'SERVICE',
        main: '',
        arrow: true,
    },
    {
        key: 4,
        href: 'https://corp.crisp.co.jp/media/',
        text: 'MEDIA',
        main: '',
        arrow: true,
    },
    {
        key: 5,
        href: 'https://corp.crisp.co.jp/openness/',
        text: 'OPENNESS',
        main: '',
        arrow: true,
    },
    {
        key: 6,
        href: 'https://corp.crisp.co.jp/company/',
        text: 'COMPANY',
        main: '',
        arrow: true,
    },
];
