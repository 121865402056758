import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import useHover from '../../hooks/useHover';
import useUserAgent from '../../hooks/useUserAgent';
import AccordionHeaderSubMenu from '../ui/accordion/AccordionHeaderSubMenu';
import ActiveLink from '../link/ActiveLink';
import storiesCategoryItems from '../section/stories/storiesCategoryItems';
import { navSubHelpItems } from '../common/navMenuItems';
import { navSubCareersItems } from '../common/navMenuItems';
import { navSubCorporateItems } from '../common/navMenuItems';
import styles from '../../styles/components/common/Header.module.scss';
import ArrowZ from '../icon/ArrowZ';

const Header = memo(() => {
    const [spMenu, setSpMenu] = useState(false);
    const onClickMenu = () => {
        setSpMenu(!spMenu);
    };
    const router = useRouter();
    const [query, setQuery] = useState(router.query);
    useEffect(() => {
        setQuery(router.query);
        if (query !== router.query) {
            setSpMenu(false);
        }
    }, [router]);

    useHover();
    const { mobileType, device } = useUserAgent();

    return (
        <header className={`${styles.container} ${spMenu ? 'is-show' : ''}`}>
            <div className={styles.wrapepr}>
                <div className={styles.inner}>
                    {/* main */}
                    <div className={styles.main}>
                        <button
                            className={styles.spButton}
                            onClick={onClickMenu}
                            aria-label="メニュー開閉ボタン"
                        >
                            <span className={styles.spButtonWrapper}>
                                <span
                                    className={`${styles.spButtonBorder} headerButtonBorder`}
                                ></span>
                                <span
                                    className={`${styles.spButtonBorder} headerButtonBorder`}
                                ></span>
                            </span>
                        </button>

                        {/* logo */}
                        <Link href="/">
                            <a className={styles.logo} aria-label="crisp">
                                <svg
                                    className={styles.logoSvg}
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M23.5467 11.6434C23.5467 13.52 21.2833 15.31 20 15.31C18.7167 15.31 16.4567 13.6434 16.4567 11.6434C16.4567 8.97668 19.4567 6.14002 20 4.02335C20.61 6.17002 23.5467 8.93668 23.5467 11.6434Z"
                                        fill="#0C3B2C"
                                    />
                                    <path
                                        d="M23.5467 30.2867C23.5467 32.62 21.1767 34.71 20 34.71C18.8233 34.71 16.4567 32.9033 16.4567 30.2867C16.4567 27.1667 20 23.4067 20 23.4067C20 23.4067 23.5467 27.1667 23.5467 30.2867Z"
                                        fill="#0C3B2C"
                                    />
                                    <path
                                        d="M28.3667 19.93C28.038 19.291 27.5472 18.7496 26.9433 18.36C26.3665 17.9802 25.6907 17.7785 25 17.78C24.0513 17.7745 23.1365 18.1323 22.4433 18.78C21.7882 18.1468 20.9128 17.7929 20.0017 17.7929C19.0906 17.7929 18.2151 18.1468 17.56 18.78C16.8684 18.1319 15.9545 17.774 15.0067 17.78C14.3149 17.7779 13.6378 17.9796 13.06 18.36C12.4562 18.7496 11.9653 19.291 11.6367 19.93C11.5946 20.0522 11.5655 20.1784 11.55 20.3067C11.5334 20.4577 11.5637 20.6101 11.6367 20.7433C11.6699 20.891 11.7408 21.0276 11.8424 21.1397C11.944 21.2519 12.073 21.3358 12.2167 21.3833C12.4823 21.4604 12.7644 21.4604 13.03 21.3833C13.1659 21.3589 13.2922 21.2969 13.3947 21.2044C13.4972 21.112 13.5718 20.9927 13.61 20.86C13.7334 20.5973 13.9305 20.3762 14.1772 20.2235C14.424 20.0708 14.7099 19.9932 15 20C15.7333 20 16.22 20.29 16.45 20.8733C16.5385 21.0738 16.687 21.2417 16.8752 21.354C17.0633 21.4663 17.2816 21.5173 17.5 21.5H17.6167C17.8214 21.4974 18.0218 21.4398 18.1967 21.3333C18.3815 21.2277 18.5257 21.0635 18.6067 20.8667C18.733 20.6045 18.9308 20.3832 19.1773 20.2284C19.4238 20.0736 19.7089 19.9914 20 19.9914C20.2911 19.9914 20.5763 20.0736 20.8227 20.2284C21.0692 20.3832 21.267 20.6045 21.3933 20.8667C21.4741 21.0626 21.617 21.2266 21.8 21.3333C21.9754 21.4423 22.1769 21.5022 22.3833 21.5067H22.5C22.7194 21.5222 22.9381 21.4689 23.1257 21.3541C23.3134 21.2394 23.4605 21.069 23.5467 20.8667C23.7767 20.2767 24.2633 20 25 20C25.2926 19.9934 25.5808 20.0726 25.829 20.2278C26.0771 20.383 26.2745 20.6074 26.3967 20.8733C26.4342 21.0064 26.5086 21.1261 26.6112 21.2187C26.7138 21.3113 26.8405 21.373 26.9767 21.3967C27.2423 21.4737 27.5244 21.4737 27.79 21.3967C27.9337 21.3491 28.0627 21.2652 28.1643 21.1531C28.2659 21.0409 28.3368 20.9043 28.37 20.7567C28.443 20.6235 28.4733 20.471 28.4567 20.32C28.4393 20.1874 28.4091 20.0568 28.3667 19.93Z"
                                        fill="#0C3B2C"
                                    />
                                    <path
                                        d="M20 40C16.0444 40 12.1776 38.827 8.88858 36.6294C5.59959 34.4317 3.03614 31.3082 1.52239 27.6537C0.00863902 23.9991 -0.387428 19.9778 0.384277 16.0982C1.15598 12.2186 3.0608 8.6549 5.85785 5.85785C8.6549 3.0608 12.2186 1.15598 16.0982 0.384277C19.9778 -0.387428 23.9991 0.00863902 27.6537 1.52239C31.3082 3.03614 34.4317 5.59959 36.6294 8.88858C38.827 12.1776 40 16.0444 40 20C39.9938 25.3024 37.8847 30.3859 34.1353 34.1353C30.3859 37.8847 25.3024 39.9938 20 40ZM20 2.21332C16.4821 2.21332 13.0432 3.25648 10.1182 5.21091C7.19324 7.16533 4.91348 9.94323 3.56724 13.1933C2.22101 16.4434 1.86878 20.0197 2.55508 23.47C3.24138 26.9203 4.9354 30.0895 7.42291 32.5771C9.91042 35.0646 13.0797 36.7586 16.53 37.4449C19.9802 38.1312 23.5566 37.7789 26.8066 36.4327C30.0567 35.0865 32.8346 32.8067 34.7891 29.8817C36.7435 26.9567 37.7866 23.5179 37.7866 20C37.7814 15.2843 35.9057 10.7633 32.5712 7.42876C29.2367 4.09426 24.7157 2.21861 20 2.21332Z"
                                        fill="#0C3B2C"
                                    />
                                </svg>
                            </a>
                        </Link>

                        {/* nav */}
                        <nav className={`${styles.nav} headerNav`}>
                            <ul className={styles.navList}>
                                <li className={styles.navItem}>
                                    <Link href="/">
                                        <a
                                            className={`${styles.navItemLink} 
                                                    ${styles.navItemLinkTop} 
                                                    js-hover__color 
                                                    headerNavLink
                                                    ${
                                                        router.pathname === '/'
                                                            ? 'is-active'
                                                            : ''
                                                    }`}
                                        >
                                            TOP
                                        </a>
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    <ActiveLink
                                        href="/menu"
                                        text="MENU"
                                        type="headerNavItemLink"
                                    />
                                </li>
                                <li className={styles.navItem}>
                                    <ActiveLink
                                        href="/location"
                                        text="STORE LOCATOR"
                                        type="headerNavItemLink"
                                    />
                                </li>
                                <li className={styles.navItem}>
                                    <ActiveLink
                                        href="/stories"
                                        text="STORIES"
                                        type="headerNavItemLink"
                                    />
                                </li>
                                <li className={styles.navItem}>
                                    <ActiveLink
                                        href="/initiatives"
                                        text="INITIATIVES"
                                        type="headerNavItemLink"
                                    />
                                </li>
                                {/* <li className={styles.navItem}>
                                    <a
                                        className={`${styles.navItemLink} js-hover js-hover__color__point`}
                                        data-hover-in-time="900"
                                        data-hover-out-time="0"
                                        href="https://replenish.crispsaladworks.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        REPLENISH
                                    </a>
                                </li> */}
                            </ul>
                            <Link href="/">
                                <a className={styles.navOrder}>
                                    <span className={styles.navOrderText}>
                                        ORDER NOW!
                                    </span>
                                </a>
                            </Link>

                            <div className={styles.navSub}>
                                <ul className={styles.navSubList}>
                                    <AccordionHeaderSubMenu
                                        title="HELP"
                                        items={navSubHelpItems}
                                    />
                                    <AccordionHeaderSubMenu
                                        title="STORIES"
                                        items={storiesCategoryItems}
                                        stories={true}
                                    />
                                    <li className={styles.navSubItem}>
                                        <Link href="/app">
                                            <a className={styles.navSubLink}>
                                                CRISP APP
                                            </a>
                                        </Link>
                                    </li>
                                    <AccordionHeaderSubMenu
                                        title="CAREERES"
                                        items={navSubCareersItems}
                                    />
                                    <AccordionHeaderSubMenu
                                        title="CORPORATE"
                                        items={navSubCorporateItems}
                                    />
                                </ul>
                            </div>
                            <div className={styles.navBlank}>
                                <ul className={styles.navBlankList}>
                                    <li className={styles.navBlankItem}>
                                        <a
                                            className={styles.navBlankLink}
                                            href="https://docs.google.com/document/d/1DTOpBknn9DVEIib91fhZ4-bmReR1lm5l4P6vrlVW81A/edit"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                className={styles.navBlankText}
                                            >
                                                PRIVACY POLICY
                                            </span>
                                            <ArrowZ color="#f9f8f3" />
                                        </a>
                                    </li>
                                    <li className={styles.navBlankItem}>
                                        <a
                                            className={styles.navBlankLink}
                                            href="https://firebasestorage.googleapis.com/v0/b/pltfrm-prod.appspot.com/o/PLATFORM%20%E3%83%A6%E3%83%BC%E3%82%B5%E3%82%99%E3%83%BC%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf?alt=media&token=8eedcd64-0ee8-40d7-a002-1b6f0a92e393"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                className={styles.navBlankText}
                                            >
                                                TERM OF USE
                                            </span>
                                            <ArrowZ color="#f9f8f3" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.navLogo}>
                                <svg
                                    className={styles.navLogoSvg}
                                    viewBox="0 0 300 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.5275 0.000732422C9.339 0.000732422 5.87498 1.58033 3.23045 4.69596C1.08869 7.23756 0 10.1711 0 13.4174C0 17.0397 1.30247 20.1791 3.87178 22.7485C6.44901 25.2901 9.60028 26.5806 13.2464 26.5806C15.6653 26.5806 18.001 25.9512 20.1982 24.7041L20.4991 24.5339V18.4491L19.5212 19.2726C18.9393 19.7635 18.3771 20.1871 17.8585 20.5315C17.3478 20.868 16.849 21.1411 16.3581 21.343C15.5346 21.7271 14.5014 21.921 13.2939 21.921C10.9225 21.921 8.97874 21.1134 7.34372 19.4507C5.7087 17.788 4.91297 15.7967 4.91297 13.3659C4.91297 10.9035 5.7087 8.8805 7.34768 7.18214C8.96686 5.48378 10.9067 4.65637 13.2741 4.65637C15.404 4.65637 17.5022 5.51149 19.5173 7.19797L20.4912 8.0135V2.0356L20.1942 1.86536C18.0644 0.626235 15.8197 0.000732422 13.5275 0.000732422Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M37.8706 13.088C38.9435 11.738 39.4898 10.1109 39.4898 8.24625C39.4898 5.85509 38.6188 3.89941 36.9007 2.43462C35.3607 1.13611 32.8745 0.474976 29.5134 0.474976H24.5925V26.1364H29.4738V16.4609L36.2277 26.1364H42.2214L34.462 15.4593C35.8318 15.008 36.9759 14.2123 37.8706 13.088ZM34.7075 8.29772C34.7075 9.76646 34.2245 11.7142 30.0558 11.7142H29.4778V5.13458H30.1983C34.2364 5.13458 34.7075 6.93982 34.7075 8.29772Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M49.1457 0.474976H44.2644V26.1364H49.1457V0.474976Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M63.3779 10.4316L60.9393 9.43401C59.3953 8.80059 58.6431 8.03652 58.6431 7.09827C58.6431 6.40943 58.9044 5.85122 59.4507 5.39199C60.0208 4.90109 60.7294 4.66356 61.6162 4.66356C62.3328 4.66356 62.9108 4.80212 63.3621 5.09112C63.6471 5.25739 64.1182 5.68495 64.7477 6.7776L65.0486 7.30017L69.0668 4.91693L68.7739 4.40623C67.0795 1.48062 64.6844 0 61.6479 0C59.4151 0 57.5188 0.680927 56.0105 2.01507C54.4784 3.36109 53.7024 5.06341 53.7024 7.07056C53.7024 10.0437 55.5156 12.3161 59.0944 13.8284L61.45 14.8062C62.028 15.0556 62.5307 15.3169 62.9464 15.5901C63.3383 15.8474 63.659 16.1206 63.9045 16.4096C64.1341 16.6827 64.3043 16.9836 64.4152 17.3082C64.526 17.6408 64.5814 18.0169 64.5814 18.4286C64.5814 19.4579 64.2647 20.2813 63.6115 20.9425C62.9622 21.5996 62.1586 21.9203 61.1649 21.9203C59.8981 21.9203 58.9677 21.4809 58.3066 20.5664C58.0612 20.2497 57.7326 19.5252 57.5386 17.8783L57.4634 17.2251L52.5425 18.3098L52.6256 18.8561C52.9819 21.2671 53.9043 23.1713 55.3652 24.5253C56.8577 25.8871 58.7619 26.576 61.0264 26.576C63.4175 26.576 65.4484 25.7723 67.0676 24.1848C68.6789 22.6052 69.4984 20.5862 69.4984 18.1752C69.4984 16.366 68.9916 14.8062 67.99 13.5473C66.9963 12.32 65.4484 11.2709 63.3779 10.4316Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M85.7968 2.69202C84.9219 1.82898 83.8966 1.23515 82.7445 0.926357C81.6361 0.625482 80.0684 0.479004 77.9424 0.479004H73.1641V26.1405H78.0454V16.2037H79.1618C81.8459 16.2037 84.0193 15.495 85.6227 14.0936C87.2498 12.6723 88.0772 10.7285 88.0772 8.32154C88.0811 6.06498 87.3131 4.17264 85.7968 2.69202ZM83.3265 8.26612C83.3265 9.69131 82.8594 11.5876 78.8332 11.5876H78.0493V5.0713H78.6748C82.8435 5.0713 83.3265 6.89635 83.3265 8.26612Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M96.5095 19.2322C96.8737 21.1087 97.6378 22.5933 98.7859 23.6543C100.065 24.8221 101.656 25.3922 103.643 25.3922C105.742 25.3922 107.448 24.7232 108.853 23.3415C110.247 21.9757 110.928 20.2892 110.928 18.1831C110.928 16.647 110.508 15.3406 109.677 14.2915C108.826 13.2187 107.44 12.2923 105.56 11.5322L103.117 10.5306C101.106 9.70317 100.084 8.54718 100.084 7.09427C100.084 6.04913 100.496 5.17025 101.312 4.48141C102.092 3.81236 103.081 3.46794 104.245 3.46794C105.191 3.46794 105.987 3.66984 106.609 4.06969C107.092 4.35077 107.583 4.8773 108.093 5.66116L110.065 4.49329C108.616 2.27236 106.719 1.18762 104.277 1.18762C102.317 1.18762 100.73 1.74978 99.4233 2.90578C98.1366 4.03406 97.5151 5.39591 97.5151 7.07052C97.5151 9.55669 99.0392 11.4055 102.179 12.7317L104.538 13.7135C105.187 13.9906 105.75 14.2876 106.221 14.5963C106.712 14.917 107.119 15.2694 107.436 15.6455C107.765 16.0334 108.006 16.4689 108.164 16.9321C108.315 17.3874 108.394 17.8901 108.394 18.4285C108.394 19.7666 107.951 20.8949 107.08 21.7778C106.205 22.6606 105.1 23.1119 103.79 23.1119C102.135 23.1119 100.852 22.4943 99.9815 21.2789C99.5697 20.7445 99.2689 19.8815 99.0749 18.67L96.5095 19.2322Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M136.225 24.9496H145.928V22.6653H138.731V1.66345H136.225V24.9496Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M159.009 0.958008L147.841 24.9488H150.505L153.233 19.0065H164.48L167.049 24.9488H169.785L159.009 0.958008ZM154.226 16.7223L158.969 6.35L163.494 16.7223H154.226Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M186.293 4.66338C185.117 3.58656 183.791 2.81458 182.35 2.36723C180.976 1.90008 179.08 1.6665 176.693 1.6665H172.152V24.9526H176.629C179.036 24.9526 180.909 24.7349 182.196 24.3034C183.569 23.8719 184.892 23.0959 186.146 21.9954C188.633 19.7982 189.892 16.8765 189.892 13.3096C189.888 9.74658 188.676 6.8368 186.293 4.66338ZM184.432 20.2257C183.486 21.0808 182.413 21.7143 181.241 22.1102C180.081 22.4783 178.629 22.6644 176.914 22.6644H174.658V3.94682H176.914C180.204 3.94682 182.742 4.75047 184.464 6.33798C186.38 8.11948 187.35 10.4631 187.35 13.3056C187.35 16.1322 186.368 18.4601 184.432 20.2257Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M245.178 4.7702C242.81 2.40279 239.932 1.20325 236.626 1.20325C233.273 1.20325 230.363 2.39487 227.976 4.74249C225.581 7.0901 224.366 9.93258 224.366 13.1947C224.366 16.92 225.696 19.9683 228.317 22.2566C230.708 24.335 233.439 25.392 236.436 25.392C239.833 25.392 242.771 24.2043 245.17 21.8686C247.553 19.5447 248.764 16.6745 248.764 13.3372C248.76 10.0197 247.553 7.13761 245.178 4.7702ZM243.404 20.2732C241.527 22.1576 239.2 23.1117 236.48 23.1117C233.966 23.1117 231.717 22.1774 229.805 20.3326C227.877 18.4838 226.895 16.0966 226.895 13.2382C226.895 10.5106 227.838 8.18275 229.702 6.32604C231.563 4.44557 233.875 3.49148 236.575 3.49148C239.287 3.49148 241.595 4.44557 243.436 6.33C245.28 8.19859 246.219 10.5422 246.219 13.3016C246.223 16.0253 245.273 18.3689 243.404 20.2732Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M258.444 14.7238L259.335 14.5338C260.839 14.2131 262.011 13.4965 262.925 12.3524C263.84 11.2044 264.287 9.86229 264.287 8.25103C264.287 6.19637 263.578 4.58906 262.118 3.34598C260.799 2.22957 258.57 1.66345 255.498 1.66345H251.765V24.9496H254.271V14.9336H255.839L262.83 24.9496H265.871L258.444 14.7238ZM254.267 12.9027V3.94773H256.175C260.886 3.94773 261.872 6.31513 261.872 8.29853C261.872 10.4007 260.859 12.9027 256.033 12.9027H254.267Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M281.647 24.9496H285.043L274.378 12.1149L284.814 1.66345H281.567L271.195 12.293V1.66345H268.689V24.9496H271.195V15.1711L272.597 13.7697L281.647 24.9496Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M285.582 19.2322C285.946 21.1087 286.71 22.5933 287.858 23.6543C289.137 24.8221 290.728 25.3922 292.716 25.3922C294.814 25.3922 296.52 24.7232 297.926 23.3415C299.319 21.9757 300 20.2892 300 18.1831C300 16.647 299.58 15.3406 298.749 14.2915C297.898 13.2187 296.512 12.2923 294.632 11.5322L292.189 10.5306C290.178 9.70317 289.157 8.54718 289.157 7.09427C289.157 6.04913 289.568 5.17025 290.384 4.48141C291.164 3.81236 292.154 3.46794 293.317 3.46794C294.264 3.46794 295.059 3.66984 295.681 4.06969C296.164 4.35077 296.655 4.8773 297.165 5.66116L299.137 4.49329C297.688 2.27236 295.792 1.18762 293.349 1.18762C291.389 1.18762 289.802 1.74978 288.496 2.90578C287.209 4.03406 286.587 5.39591 286.587 7.07052C286.587 9.55669 288.112 11.4055 291.251 12.7317L293.61 13.7135C294.26 13.9906 294.822 14.2876 295.293 14.5963C295.784 14.917 296.192 15.2694 296.508 15.6455C296.837 16.0334 297.078 16.4689 297.237 16.9321C297.387 17.3874 297.466 17.8901 297.466 18.4285C297.466 19.7666 297.023 20.8949 296.152 21.7778C295.277 22.6606 294.173 23.1119 292.862 23.1119C291.207 23.1119 289.925 22.4943 289.054 21.2789C288.642 20.7445 288.341 19.8815 288.147 18.67L285.582 19.2322Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M215.252 26.446L225.581 1.66345H222.861L215.331 20.0485L211.151 10.2819L214.528 2.85112H205.648L208.668 10.2701L204.325 20.0485L196.796 1.66345H194.076L204.405 26.446L209.84 13.16L215.252 26.446ZM211.127 5.0285L209.983 7.55426L208.902 5.0285H211.127Z"
                                        fill="#F9F8F3"
                                    />
                                    <path
                                        d="M123.173 0.958008L112.005 24.9488H114.673L117.401 19.0065L117.535 18.6977C119.142 19.8221 121.051 20.4317 123.054 20.4317C125.037 20.4317 126.93 19.8339 128.529 18.7255L128.648 19.0026L131.217 24.9449H133.953L123.173 0.958008ZM118.509 16.461L123.133 6.35L127.555 16.4887C124.911 18.579 121.142 18.5711 118.509 16.461Z"
                                        fill="#F9F8F3"
                                    />
                                </svg>
                            </div>
                        </nav>
                    </div>
                    {device === 'deviceMobile' ? (
                        <a
                            className={`${styles.order} js-hover js-hover__btn__sp-white`}
                            // href={switchLink()}
                            href="https://crisp.page.link/wpYq"
                            data-hover-in-time="400"
                            data-hover-out-time="0"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className={styles.orderText}>
                                ORDER N
                                <span className={styles.orderTextParent}>
                                    O
                                    <span
                                        className={`${styles.orderTextParentInnerWord} orderTextParentInnerWord`}
                                    >
                                        <span
                                            className={`${styles.orderTextWord} orderTextWord`}
                                        >
                                            O
                                        </span>
                                        <span
                                            className={`${styles.orderTextWord} orderTextWord`}
                                        >
                                            O
                                        </span>
                                    </span>
                                </span>
                                <span className={styles.orderTextParent}>
                                    W
                                    <span
                                        className={`${styles.orderTextParentInnerMark} orderTextParentInnerMark`}
                                    >
                                        <span
                                            className={`${styles.orderTextWord} orderTextWord`}
                                        >
                                            !
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </a>
                    ) : (
                        router.pathname !== '/app' && (
                            <Link href="/app">
                                <a
                                    className={`${styles.order} js-hover js-hover__btn__sp-white`}
                                    // href={switchLink()}
                                    data-hover-in-time="400"
                                    data-hover-out-time="0"
                                >
                                    <span className={styles.orderText}>
                                        DOWNL
                                        <span
                                            className={styles.orderTextParent}
                                        >
                                            O
                                            <span
                                                className={`${styles.orderTextParentInnerWord} orderTextParentInnerWord`}
                                            >
                                                <span
                                                    className={`${styles.orderTextWord} orderTextWord`}
                                                >
                                                    O
                                                </span>
                                                <span
                                                    className={`${styles.orderTextWord} orderTextWord`}
                                                >
                                                    O
                                                </span>
                                            </span>
                                            AD &nbsp;
                                        </span>
                                        <span
                                            className={styles.orderTextParent}
                                        >
                                            APP
                                            <span
                                                className={`${styles.orderTextParentInnerMark} orderTextParentInnerMark`}
                                            >
                                                <span
                                                    className={`${styles.orderTextWord} orderTextWord`}
                                                >
                                                    !
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </Link>
                        )
                    )}
                </div>
            </div>
        </header>
    );
});

Header.displayName = 'Header';

export default Header;
