import Link from 'next/link';
import { memo } from 'react';
import useSpAccordion from '../../../hooks/useSpAccordion';
import ArrowZ from '../../icon/ArrowZ';
import styles from '../../../styles/components/common/Header.module.scss';

const AccordionHeaderSubMenu = memo(({ title, items }) => {
    const { contentRef, active, toggleActive } = useSpAccordion();

    return (
        <li
            className={`${styles.navSubItem} ${
                active ? 'is-active' : ''
            } accordionItem`}
        >
            <button className={styles.navSubItemTrigger} onClick={toggleActive}>
                <span className={styles.navSubItemTriggerText}>{title}</span>
                <span className={styles.navSubItemTriggerSvg}>
                    <svg
                        className={`${styles.navSubItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                    <svg
                        className={`${styles.navSubItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                </span>
            </button>
            <div
                className={`${styles.navSubItemTarget} ${styles.navStoriesItemTarget} accordionTarget`}
                ref={contentRef}
            >
                <ul className={styles.navSubItemTargetList}>
                    {items.map((data) => {
                        return (
                            <li
                                className={styles.navSubItemTargetItem}
                                key={data.key}
                            >
                                <Link href={data.href}>
                                    {data.arrow ? (
                                        <a
                                            className={
                                                styles.navSubItemTargetItemLink
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                className={
                                                    styles.navSubItemTargetItemText
                                                }
                                            >
                                                {data.text}
                                            </span>
                                            <ArrowZ color="#f9f8f3" />
                                        </a>
                                    ) : (
                                        <a
                                            className={
                                                styles.navSubItemTargetItemLink
                                            }
                                        >
                                            <span
                                                className={
                                                    styles.navSubItemTargetItemText
                                                }
                                            >
                                                {data.text}
                                            </span>
                                        </a>
                                    )}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </li>
    );
});

AccordionHeaderSubMenu.displayName = 'AccordionHeaderSubMenu';

export default AccordionHeaderSubMenu;
