import { memo } from 'react';
import styles from '../../styles/components/icon/ArrowZ.module.scss';

const ArrowZ = memo(({ color = '#0c3b2c' }) => {
    return (
        <span className={`${styles.container} js-hover__arrow__z`}>
            <svg
                className={`${styles.arrow} js-hover__arrow__z-svg`}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.53552 10.6079L10.6078 3.53559"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.6072 10.6072L10.6078 3.53671L3.53613 3.53613"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <svg
                className={`${styles.arrow} js-hover__arrow__z-svg`}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.53552 10.6079L10.6078 3.53559"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.6072 10.6072L10.6078 3.53671L3.53613 3.53613"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </span>
    );
});

ArrowZ.displayName = 'ArrowZ';

export default ArrowZ;
