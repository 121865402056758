import React, { useEffect } from 'react';

const useHover = () => {
    useEffect(() => {
        const html = document.querySelector('html');
        const arrowLinks = document.querySelectorAll('.js-hover');
        const options = [];
        const eventHandler = {
            mouseEnter: null,
            mouseLeave: null,
        };
        const _defaultDuration = {
            in: 600,
            out: 600,
        };
        function _setFlag() {
            const length = arrowLinks.length;
            for (let i = length; i--; ) {
                options[i] = {
                    isHovering: false,
                    isAnimating: false,
                };
                let target = arrowLinks[i];
                target.setAttribute('data-hover-num', i);
            }
        }
        _setFlag();
        function _setEventListener() {
            eventHandler.mouseEnter = (e) => {
                e.preventDefault();
                _onMouseEnter(e.currentTarget);
            };
            eventHandler.mouseLeave = (e) => {
                e.preventDefault();
                _onMouseLeave(e.currentTarget);
            };
            [].forEach.call(arrowLinks, ($el) => {
                $el.addEventListener(
                    'mouseenter',
                    eventHandler.mouseEnter,
                    false
                );
                $el.addEventListener(
                    'mouseleave',
                    eventHandler.mouseLeave,
                    false
                );
            });
        }
        _setEventListener();
        function _onMouseEnter($this) {
            const num = Number($this.dataset['hoverNum']);
            options[num].isHovering = true;
            if (!options[num].isAnimating) {
                _startMouseEnter($this, num);
            }
        }
        function _onMouseLeave($this) {
            const num = Number($this.dataset[`hoverNum`]);
            options[num].isHovering = false;
            if (!options[num].isAnimating) {
                _startMouseLeave($this, num);
            }
        }
        function _startMouseEnter($this, num) {
            options[num].isAnimating = true;
            // if (!html.classList.contains('deviceMobile')) {
            $this.classList.add('is-hover');
            // }
            const time =
                Number($this.dataset['hoverInTime']) || _defaultDuration.in;
            setTimeout(() => {
                _completeMouseEnter($this, num);
            }, time);
        }
        function _startMouseLeave($this, num) {
            options[num].isAnimating = true;
            // if (!html.classList.contains('deviceMobile')) {
            $this.classList.remove('is-hover');
            $this.classList.add('is-hover-out');
            // }
            const time =
                Number($this.dataset['hoverOutTime']) || _defaultDuration.out;
            setTimeout(() => {
                _completeMouseLeave($this, num);
            }, time);
        }
        function _completeMouseEnter($this, num) {
            options[num].isAnimating = false;
            if (!options[num].isHovering) {
                _startMouseLeave($this, num);
            }
        }
        function _completeMouseLeave($this, num) {
            options[num].isAnimating = false;
            // if (!html.classList.contains('deviceMobile')) {
            $this.classList.remove('is-hover-out');
            // }

            if (options[num].isHovering) {
                _startMouseEnter($this, num);
            }
        }
    }, []);
};

export default useHover;
