import Link from 'next/link';
import { memo } from 'react';
import useHover from '../../../hooks/useHover';
import useSpAccordion from '../../../hooks/useSpAccordion';
import ArrowZ from '../../icon/ArrowZ';
import styles from '../../../styles/components/common/Footer.module.scss';

const AccordionFooterSubMenu = memo(({ title, items, have }) => {
    useHover();
    const { contentRef, active, toggleActive } = useSpAccordion();

    return (
        <li
            className={`${styles.navItem} ${
                active ? 'is-active' : ''
            } accordionSpItem footerNavItem`}
        >
            {have && (
                <Link href="/app">
                    <a className={styles.navLink}>CRISP APP</a>
                </Link>
            )}
            <button className={styles.navItemTrigger} onClick={toggleActive}>
                <span className={styles.navItemTriggerText}>{title}</span>
                <span className={styles.navItemTriggerSvg}>
                    <svg
                        className={`${styles.navItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                    <svg
                        className={`${styles.navItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                </span>
            </button>
            <div
                className={`${styles.navItemTarget} accordionTarget`}
                ref={contentRef}
            >
                <ul className={styles.navItemTargetList}>
                    {items.map((data) => {
                        return (
                            <li
                                className={`${styles.navItemTargetItem} ${data.main}`}
                                key={data.key}
                            >
                                <Link href={data.href}>
                                    {data.arrow ? (
                                        <a
                                            className={`${styles.navItemTargetItemLink} link js-hover`}
                                            data-hover-in-time="600"
                                            data-hover-out-time="0"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                className={`${styles.navItemTargetItemText} linkText js-hover__opacity`}
                                            >
                                                {data.text}
                                            </span>
                                            <ArrowZ color="#f9f8f3" />
                                        </a>
                                    ) : (
                                        <a
                                            className={`${styles.navItemTargetItemLink} link js-hover`}
                                            data-hover-in-time="600"
                                            data-hover-out-time="0"
                                        >
                                            <span
                                                className={`${styles.navItemTargetItemText} linkText js-hover__opacity`}
                                            >
                                                {data.text}
                                            </span>
                                        </a>
                                    )}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </li>
    );
});

AccordionFooterSubMenu.displayName = 'AccordionFooterSubMenu';

export default AccordionFooterSubMenu;
