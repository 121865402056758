import Link from 'next/link';
import { memo } from 'react';
import useHover from '../../../hooks/useHover';
import useSpAccordion from '../../../hooks/useSpAccordion';
import styles from '../../../styles/components/common/Footer.module.scss';

const AccordionFooterStoriesMenu = memo(({ title, items }) => {
    useHover();
    const { contentRef, active, toggleActive } = useSpAccordion();

    return (
        <li
            className={`${styles.navItem} ${
                active ? 'is-active' : ''
            } accordionSpItem footerNavItem`}
        >
            <button className={styles.navItemTrigger} onClick={toggleActive}>
                <span className={styles.navItemTriggerText}>{title}</span>
                <span className={styles.navItemTriggerSvg}>
                    <svg
                        className={`${styles.navItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                    <svg
                        className={`${styles.navItemTriggerSvgBorder} accordionItemSvg accordionItemSvgWhite`}
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1H11"
                            stroke="#F9F8F3"
                            strokeLinecap="round"
                        />
                    </svg>
                </span>
            </button>
            <div
                className={`${styles.navItemTarget} ${styles.navStoriesItemTarget} accordionTarget`}
                ref={contentRef}
            >
                <Link href={`/stories`}>
                    <a className={styles.navItemTargetLink}>{title}</a>
                </Link>
                <ul className={styles.navItemTargetList}>
                    {items.map((data) => {
                        return (
                            <li
                                className={`${styles.navItemTargetItem}`}
                                key={data.key}
                            >
                                <Link href={data.href}>
                                    <a
                                        className={`${styles.navItemTargetItemLink} link js-hover`}
                                        data-hover-in-time="600"
                                        data-hover-out-time="0"
                                    >
                                        <span
                                            className={`${styles.navItemTargetItemText} linkText js-hover__opacity`}
                                        >
                                            {data.text}
                                        </span>
                                    </a>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </li>
    );
});

AccordionFooterStoriesMenu.displayName = 'AccordionFooterStoriesMenu';

export default AccordionFooterStoriesMenu;
